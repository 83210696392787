var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CHeader",
        { attrs: { "with-subheader": "" } },
        [
          _c("CToggler", {
            staticClass: "ml-3 d-lg-none",
            attrs: { "in-header": "" },
            on: {
              click: function($event) {
                return _vm.$store.commit("toggleSidebarMobile")
              }
            }
          }),
          _c("CToggler", {
            staticClass: "ml-3 d-md-down-none",
            attrs: { "in-header": "" },
            on: {
              click: function($event) {
                return _vm.$store.commit("toggleSidebarDesktop")
              }
            }
          }),
          _c(
            "CHeaderBrand",
            { staticClass: "mx-auto d-lg-none", attrs: { to: "/" } },
            [
              _vm.brandLogoSrc
                ? _c("CIcon", {
                    staticClass: "d-none d-sm-block",
                    attrs: {
                      name: "logo",
                      src: _vm.brandLogoSrc,
                      height: "48",
                      alt: "Logo"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "CHeaderNav",
            { staticClass: "d-md-down-none mr-auto" },
            [
              _c("CHeaderNavItem", { staticClass: "pr-2" }, [
                _c("h4", [_vm._v(_vm._s(_vm.displayName))])
              ]),
              _c(
                "CHeaderNavItem",
                { staticClass: "px-2" },
                [
                  _c(
                    "CHeaderNavLink",
                    { attrs: { to: "/home" } },
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-truck" }
                      }),
                      _vm._v(" Fleet ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "CHeaderNavItem",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "CHeaderNavLink",
                        { attrs: { to: "/users", exact: "" } },
                        [
                          _c("CIcon", { attrs: { name: "cil-user" } }),
                          _vm._v(" Team ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.branch
                ? _c("CHeaderNavItem", { staticClass: "px-0" }, [
                    _c(
                      "span",
                      { staticClass: " mt-0 pt-0" },
                      [
                        _c("CIcon", { attrs: { name: "cil-home" } }),
                        _vm._v(" Branch: " + _vm._s(_vm.branchName) + " ")
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "CHeaderNavItem",
                { staticClass: "mx-0 px-0" },
                [
                  _vm.$auth.role === "admin"
                    ? _c("BranchSelectorComponent", {
                        on: { change: _vm.onBranchChanged }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CHeaderNav",
            [
              _vm.$auth.permissions == "admin"
                ? _c(
                    "CHeaderNavItem",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      staticClass: "px-3"
                    },
                    [
                      _vm.$auth.role !== "admin"
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: "Switch Roles",
                                  expression: "'Switch Roles'"
                                }
                              ],
                              staticClass: "c-header-nav-btn",
                              on: {
                                click: function($event) {
                                  return _vm.handleRoleSwitch("admin")
                                }
                              }
                            },
                            [_c("CIcon", { attrs: { name: "cil-transfer" } })],
                            1
                          )
                        : _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: "Switch Roles",
                                  expression: "'Switch Roles'"
                                }
                              ],
                              staticClass: "c-header-nav-btn",
                              on: {
                                click: function($event) {
                                  return _vm.handleRoleSwitch("viewer")
                                }
                              }
                            },
                            [_c("CIcon", { attrs: { name: "cil-transfer" } })],
                            1
                          )
                    ]
                  )
                : _vm._e(),
              false
                ? _c(
                    "CHeaderNavItem",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "c-header-nav-btn",
                          on: {
                            click: function() {
                              return _vm.$store.commit("toggle", "darkMode")
                            }
                          }
                        },
                        [
                          _vm.$store.state.darkMode
                            ? _c("CIcon", { attrs: { name: "cil-sun" } })
                            : _c("CIcon", { attrs: { name: "cil-moon" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CToaster",
                { attrs: { autohide: 1000 } },
                [
                  _vm._l(_vm.fixtoasts, function(toast) {
                    return [
                      _c(
                        "CToast",
                        {
                          key: "toast" + toast,
                          attrs: {
                            show: true,
                            color: _vm.color(toast),
                            header: _vm.toastHeader(toast)
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.toastMessage(toast)) + " ")]
                      )
                    ]
                  })
                ],
                2
              ),
              _c("TheHeaderDropdownMssgs"),
              _c("TheHeaderDropdownAccntSimple"),
              _c("CHeaderNavItem", { staticClass: "px-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "c-header-nav-btn",
                    on: {
                      click: function($event) {
                        return _vm.$store.commit("toggle", "asideShow")
                      }
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "mr-2",
                      attrs: { size: "lg", name: "cil-applications-settings" }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          false
            ? _c(
                "CSubheader",
                { staticClass: "px-3" },
                [_c("CBreadcrumbRouter", { staticClass: "border-0 mb-0" })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }