<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar :role="role" :items="items" />
    <TheAside class="sidebar"/>
    <CWrapper>
      <TheHeader  />
      <div class="c-body">
        <main class="c-main main-container">
          <CContainer fluid>
            
            <transition name="fade" mode="out-in">
              <keep-alive :exclude="keepAliveExcludeList">
                
              <router-view :key="$route.fullPath"></router-view> 
              </keep-alive>
            </transition>
            
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>



<script>
import TheSidebar from '@/layouts/containers/TheSidebar'
import TheHeader from '@/layouts/containers/TheHeader'
import TheFooter from '@/layouts/containers/TheFooter'
import TheAside from '@/layouts/containers/TheAside'




export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  },
  props:{
    // Allow user to Set the Sidebar Items and the Container will render accordingly 
    sidebarItems: {
      type: Array, 
    }, 
    keepAliveExcludeList:{
      type: Array | String,
      default:()=> ['ReportViewer'], 
    }
  }, 
  computed:{
    items(){
      return this.sidebarItems; 
    },
    role(){
      if(!this.$auth.role){
        return "viewer"; 
      }
      return this.$auth.role;
    }, 
    branchName(){
      return "";
    }
  }, 
  methods:{
    handleBranchSelectClick(e){

    }
  }
}
</script>

