var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      staticClass: "sidebar",
      attrs: {
        minimize: _vm.minimize,
        unfoldable: "",
        show: _vm.show,
        "color-scheme": "dark"
      },
      on: {
        "update:show": function(value) {
          return _vm.$store.commit("set", ["sidebarShow", value])
        }
      }
    },
    [
      _c(
        "CSidebarBrand",
        { staticClass: "d-md-down-none", attrs: { to: "/" } },
        [
          _c("CIcon", {
            staticClass: "c-sidebar-brand-full",
            attrs: {
              name: "logo",
              size: "custom-size",
              src: _vm.maximizedLogo,
              height: 35,
              viewBox: "0 0 556 134"
            }
          }),
          _c("CIcon", {
            staticClass: "c-sidebar-brand-minimized",
            attrs: {
              name: "logo",
              size: "custom-size",
              height: 35,
              src: _vm.minimizedLogo,
              alt: "Logo",
              viewBox: "0 0 110 134"
            }
          })
        ],
        1
      ),
      _c(
        "CCol",
        {
          staticClass: "m-0 p-0",
          staticStyle: { "background-color": "rgba(0,0,0,0.4)" }
        },
        [
          _c("CRenderFunction", {
            key: _vm.sidebarItemsKey,
            attrs: { flat: "", contentToRender: _vm.computedSidebar }
          })
        ],
        1
      ),
      _c("CSidebarMinimizer", {
        staticClass: "c-d-md-down-none",
        nativeOn: {
          click: function($event) {
            return _vm.$store.commit("toggle", "sidebarMinimize")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }