<template>
  <CFooter :fixed="false">
    <div>
      
      <span class="ml-1" v-if="version"> Version {{version}}</span>
      <span class="ml-1"> Copyright &copy; {{copyright}}</span>
    </div>
    <div class="mfs-auto">
      
    </div>
  </CFooter>
</template>

<script>
const COPYRIGHT="2022 Aarcomm Systems ";
export default {
  name: 'TheFooter', 

  computed:{
  copyright(){
      return process.env.VUE_APP_COPYRIGHT ? process.env.VUE_APP_COPYRIGHT: COPYRIGHT;
    }, 
    version(){
      return process.env.VUE_APP_VERSION; 
    }
  }
}
</script>
