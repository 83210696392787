<script>
/** Adds only the Sidebar Items  required for our main Application
 * Home => Home Admin Screen
 * Dashboard => Our Dashboard View
 * Rules => Our Rules Engine
 * Reports => The Reports View
 *
 * NOTE: These items are often applicaton specific, and therefore it is
 * Recommended to define your sidebar items for your application in a separate file and pass
 * as Props to this component
 */

const defaultSidebarItems = [
  {
    _name: "CSidebarNavItem",
    name: "Home",
    to: "/home",
    icon: "cil-home"
  },
  {
    _name: "CSidebarNavItem",
    name: "Fleet",
    to: "/fleet", // TODO: this should be device / dashboards.
    icon: "cil-truck"
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Reports",
    to: "/reports",
    icon: "cil-chart",
    roles: ["admin"],
    items: [
      {
        to: "/reports",
        name: "Daily Reports"
      }
    ]
  },

  {
    _name: "CSidebarNavItem",
    name: "Team",
    to: "/users",
    icon: "cil-user",
    roles: ["admin"],
    items: [{}]
  },

  {
    _name: "CSidebarNavDropdown",
    name: "Advanced",
    icon: "cil-settings",
    roles: ["admin"],
    items: [
      {
        _name: "CSidebarNavItem",
        name: "Files & Software",

        to: "/software-files"
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Geofence",
        to: "/app/geofence",
        icon: "cil-settings",
        //roles: ["admin","editor"], // This line toggles any special privileges required to view
        items: [
          {
            to: "/app/geofence",
            name: "Geofences"
          },
          {
            to: "/app/geofenceCategories",
            name: "Geofence Tags"
          }
        ]
      }
    ]
  },
  {
    _name: "CSidebarNavDivider",
    _class: "m-2"
  },
  /** KH: Added the Feedback Link  */
  {
    _name: "CSidebarNavTitle",
    _children: ["More"]
  },
  {
    _name: "CSidebarNavItem",
    name: "Documentation",
    icon: "cil-book",
    to: "/documents"
  },
  {
    _name: "CSidebarNavItem",
    name: "Provide Us Feedback",
    icon: "cil-envelope-closed",
    href: "mailto:feedback@aarcomm.com?subject=Aarcomm IoT Feedback"
  }
];

export default {
  name: "nav",
  props: {
    items: {
      type: Array,
      default: () => defaultSidebarItems
    }
  },

  computed: {
    sidebarItems() {
      return this.items;
    }
  }
};
</script>
