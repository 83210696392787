var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "CDropdown",
        {
          staticClass: "c-header-nav-item mx-2 d-md-down-none",
          attrs: {
            caret: false,
            placement: "bottom-end",
            "in-nav": "",
            "add-menu-classes": "pt-0"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "toggler",
                fn: function() {
                  return [
                    _c(
                      "CHeaderNavLink",
                      [
                        _c("CIcon", { attrs: { name: "cil-envelope-closed" } }),
                        _c(
                          "CBadge",
                          { attrs: { shape: "pill", color: "info" } },
                          [_vm._v(_vm._s(_vm.itemsCount))]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2183600348
          )
        },
        [
          _c(
            "CDropdownHeader",
            { staticClass: "text-center bg-light", attrs: { tag: "div" } },
            [
              _c("strong", [
                _vm._v("You have " + _vm._s(_vm.itemsCount) + " messages")
              ])
            ]
          ),
          _c("CDropdownItem", [
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "pt-3 mr-3 float-left" }, [
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img",
                    attrs: {
                      src: "img/avatars/7.jpg",
                      alt: "admin@bootstrapmaster.com"
                    }
                  }),
                  _c("span", { staticClass: "avatar-status bg-success" })
                ])
              ]),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("John Doe")
                ]),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("Just now")
                ])
              ]),
              _c(
                "div",
                { staticClass: "text-truncate font-weight-bold" },
                [
                  _c("CIcon", {
                    staticClass: "text-danger",
                    attrs: { name: "cil-warning" }
                  }),
                  _vm._v(" Important message ")
                ],
                1
              ),
              _c("div", { staticClass: "small text-muted text-truncate" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt..."
                )
              ])
            ])
          ]),
          _c("CDropdownItem", { attrs: { href: "#" } }, [
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "pt-3 mr-3 float-left" }, [
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img",
                    attrs: {
                      src: "img/avatars/6.jpg",
                      alt: "admin@bootstrapmaster.com"
                    }
                  }),
                  _c("span", { staticClass: "avatar-status bg-warning" })
                ])
              ]),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Jane Doe")
                ]),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("5 minutes ago")
                ])
              ]),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet")
              ]),
              _c("div", { staticClass: "small text-muted text-truncate" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt..."
                )
              ])
            ])
          ]),
          _c("CDropdownItem", { attrs: { href: "#" } }, [
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "pt-3 mr-3 float-left" }, [
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img",
                    attrs: {
                      src: "img/avatars/5.jpg",
                      alt: "admin@bootstrapmaster.com"
                    }
                  }),
                  _c("span", { staticClass: "avatar-status bg-danger" })
                ])
              ]),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Janet Doe")
                ]),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM")
                ])
              ]),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet")
              ]),
              _c("div", { staticClass: "small text-muted text-truncate" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt..."
                )
              ])
            ])
          ]),
          _c("CDropdownItem", { attrs: { href: "#" } }, [
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "pt-3 mr-3 float-left" }, [
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img",
                    attrs: {
                      src: "img/avatars/4.jpg",
                      alt: "admin@bootstrapmaster.com"
                    }
                  }),
                  _c("span", { staticClass: "avatar-status bg-info" })
                ])
              ]),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [_vm._v("Joe Doe")]),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("4:03 AM")
                ])
              ]),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet")
              ]),
              _c("div", { staticClass: "small text-muted text-truncate" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt..."
                )
              ])
            ])
          ]),
          _c(
            "CDropdownItem",
            { staticClass: "border-top text-center", attrs: { href: "#" } },
            [_c("strong", [_vm._v("View all messages")])]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }