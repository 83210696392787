<template>
  <div>
    <CHeader with-subheader>
      <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
      />
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
      />
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon
          class="d-none d-sm-block"
          v-if="brandLogoSrc"
          name="logo"
          :src="brandLogoSrc"
          height="48"
          alt="Logo"
        />
      </CHeaderBrand>
      <CHeaderNav class="d-md-down-none mr-auto">
        <CHeaderNavItem class="pr-2">
          <h4>{{ displayName }}</h4>
        </CHeaderNavItem>

        <CHeaderNavItem class="px-2">
          <CHeaderNavLink to="/home">
            <CIcon name="cil-truck" class="mr-1" /> Fleet
          </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-3" v-if="isAdmin">
          <CHeaderNavLink to="/users" exact>
            <CIcon name="cil-user" /> Team
          </CHeaderNavLink>
        </CHeaderNavItem>
        <!-- Put your Other quick Nav Links here -->
        <CHeaderNavItem class="px-0" v-if="branch">

            <span class=" mt-0 pt-0">
            <CIcon name="cil-home"  />
            Branch: {{ branchName }}
          </span>
          
        </CHeaderNavItem>
        <CHeaderNavItem class="mx-0 px-0">

          <BranchSelectorComponent v-if="$auth.role === 'admin'"
          @change="onBranchChanged" >
          </BranchSelectorComponent>
          
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav>
        <CHeaderNavItem class="px-3" v-show="false" v-if="$auth.permissions == 'admin'">
          <button
            v-if="$auth.role !== 'admin'"
            @click="handleRoleSwitch('admin')"
            class="c-header-nav-btn"
            v-c-tooltip="'Switch Roles'"
          >
            <CIcon name="cil-transfer" />
          </button>
          <button
            v-else
            @click="handleRoleSwitch('viewer')"
            class="c-header-nav-btn"
            v-c-tooltip="'Switch Roles'"
          >
            <CIcon name="cil-transfer" />
          </button>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-3" v-if="false">
          <CButton
            @click="() => $store.commit('toggle', 'darkMode')"
            class="c-header-nav-btn"
          >
            <CIcon v-if="$store.state.darkMode" name="cil-sun" />
            <CIcon v-else name="cil-moon" />
          </CButton>
        </CHeaderNavItem>
        <CToaster :autohide="1000">
          <template v-for="toast in fixtoasts">
            <CToast
              :key="'toast' + toast"
              :show="true"
              :color="color(toast)"
              :header="toastHeader(toast)"
            >
              {{ toastMessage(toast) }}
            </CToast>
          </template>
        </CToaster>
        <!--TheHeaderDropdownNotif/-->
        <TheHeaderDropdownMssgs />
        <TheHeaderDropdownAccntSimple />
        <CHeaderNavItem class="px-3">
          <button
            class="c-header-nav-btn"
            @click="$store.commit('toggle', 'asideShow')"
          >
            <CIcon size="lg" name="cil-applications-settings" class="mr-2" />
          </button>
        </CHeaderNavItem>
      </CHeaderNav>


      <CSubheader class="px-3" v-if="false">
        <!-- Temporarily Removed until future release-->
        <CBreadcrumbRouter  class="border-0 mb-0" />
      </CSubheader>
    </CHeader>
    
  </div>
</template>

<script>
import TheHeaderDropdownAccntSimple from "./TheHeaderDropdownAccntSimple";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
import TheHeaderDropdownTasks from "./TheHeaderDropdownTasks";
import TheHeaderDropdownMssgs from "./TheHeaderDropdownMssgs";

import BranchSelectorComponent from "@/components/BranchSelectorComponent"; 

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccntSimple,

    TheHeaderDropdownMssgs,
    BranchSelectorComponent
  },
  props: {
    logoPath: String
  },
  data() {
    return {
      toasts: 0,
      branchSelector: {
        // Form Contents
        showSelectBranch: false,
        selectedBranch: undefined
      }
    };
  },
  computed: {
    
    branch() {
      let branch_id = this.$auth.branch_id;

      return this.$store.getters.selectedBranch;
      
    },
    branchName() {
      if (this.branch) {
        return this.branch.name;
      }
      return ''; 
    },
    branchUrl() {
      if (this.branch) {
        return "/app/branches/" + this.branch.branch_id;
      }
      return ''; 
    },
    fixtoasts() {
      // return this.$fixtoasts.count;
      return this.$store.getters.fixtoasts.count;
    },

    isAdmin() {
      if (this.$auth.role == "admin") {
        return true;
      }
      return false;
    },
    // TODO: Ensure that your app passes in the Logo.
    brandLogoSrc() {
      //return this.logoPath; //
      let logo = this.$store.getters.logos.lg || this.$auth.userLogo;
      if (!logo) {
        return "";
      }
      if (logo.startsWith("/")) {
        return logo;
      }
      return `/${logo}`;
      //this.$user.logoPath;
      // return this.logoPath?this.logoPath: "logo.png"; //"/Aarcomm Logo.png"; // Should not be Hardcoded
    },
    displayName() {
      if (!this.$auth.user) {
        return "";
      }
      let name = this.$auth.user.name;
      if (this.$auth.userData && this.$auth.userData.first) {
        name = `${this.$auth.userData.first} ${this.$auth.userData.last}`;
      }
      return name;
    }
  },
  created() {},
  methods: {
    modalShown(value, e) {
      console.log(value, e);
    },
    getBranchImg(branchInfo) {
      let id;
      try {
        id = branchInfo ? branchInfo.metadata.code : "";
        id = id.toLowerCase();
        if (!id) {
          return "";
        }
      } catch (err) {
        console.log(err);
        return "";
      }
      return `https://i2.wp.com/cdn.auth0.com/avatars/${id}.png?ssl=1`;
    },
    handleRoleSwitch(newRole) {
      try {
        if (newRole != this.$auth.role) {
          this.$auth.role = newRole;
          this.$router.push({ name: "Home" });
        }
      } catch (err) {
        console.log(err);
        return "";
      }
      //return `https://i2.wp.com/cdn.auth0.com/avatars/${id}.png?ssl=1`;
    },
    handleBranchChange(item, index) {
      this.branchSelector.selectedBranch = item;
      //this.$store.commit("selectBranch", item.branch_id);
    },

    toastHeader(toast) {
      if (toast && toast.header) {
        return toast.header;
      }
      return "";
    },
    toastMessage(toast) {
      console.log(toast);
      if (toast && toast.message) {
        return toast.message;
      }
      return this.$store.getters.fixtoasts.toast.message;
    },

    onBranchChanged(e, item){
      this.$router.push("/home"); 
    }, 
    onBranchSelect(e) {
      let item = this.branchSelector.selectedBranch;
      this.$store.commit("selectBranch", item.branch_id);
    },
    color(toast) {
      return this.$store.getters.fixtoasts.toast.error ? "danger" : "info";
    }
  }
};
</script>
