<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    color-scheme="dark"
    class="sidebar"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
  >
  
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :src="maximizedLogo"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        :src="minimizedLogo"
        alt="Logo"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CCol class="m-0 p-0" style="background-color:rgba(0,0,0,0.4)">
      <CRenderFunction flat :contentToRender="computedSidebar" :key="sidebarItemsKey" />
    </CCol>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>


<script>
import SidebarItems from "./SidebarItems";

const path = require("path"); 

export default {
  name: "TheSidebar",
  extends: SidebarItems,
  props: {
    minLogoPath: String, // User can Specify Path or URL to a minimized version of their Logo
    maxLogoPath: String, // User can Specify a Path or URL to a maximized version of their Logo
    role: String,
    root: {
      type: String, 
      default: ""
    }
  },
  data(){
    return {
      sidebarItemsKey: 0, 
      
      //userRole: "viewer"
    } 
  },
  computed: {
    
     userRole(){
        return this.$auth.role?this.$auth.role:"viewer";
      
    },
    computedSidebar(){
      return [
        {
          _name: "CSidebarNav",
          _children: this.computedSidebarItems
        }
      ];
    },
    computedSidebarItems(){
      
        if(!this.userRole){
          return []; 
        }
        else{
          return this.sidebarItems.filter(item => {
              
        return !item.roles || item.roles.includes(this.userRole);
      });
        }
    },
    maximizedLogo() {
      
      // Must ensure URL is relative to root 
      
      if(!this.$store.getters.logos.lg){
        return ""; // Show Default 
      }
      if(this.$store.getters.logos.lg.startsWith("/"))
          return this.$store.getters.logos.lg;
      else{
        return path.join(this.root,this.$store.getters.logos.lg);
      }
      //return this.$auth.userLogo;
      //return  this.maxLogoPath? this.maxLogoPath:"";//"logo.png" // /Aarcomm Logo.png"; // TODO: Get from users settings.
    },
    minimizedLogo() {
       if(!this.$store.getters.logos.sm){
        return ""; // Show Default 
      }
      if(this.$store.getters.logos.sm.startsWith("/"))
          return this.$store.getters.logos.sm;
      else{
        return path.join(this.root,this.$store.getters.logos.sm);
      }
      
      //return this.$auth.userLogoSm; //"/Aarcomm.jfif"; // TODO: Get from users settings.
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    }
  }, 
  mounted(){

    this.sidebarItemsKey+=1; 
    this.$forceUpdate();
  },
  methods:
  {
   
  }
};
</script>


<style scoped>
.sidebar {
  background-image: url("https://images.pexels.com/photos/3512848/pexels-photo-3512848.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  /*background-image: url("https://images.pexels.com/photos/159298/gears-cogs-machine-machinery-159298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");*/

  background-repeat: no-repeat;
  background-attachment: float;

  background-size: cover;

  background-color: #3f3f3f;

/*
  //display: flex;
  //align-items: center;
  //background-size: 1800px 900px;
  //filter:blur(4px);
  //-webkit-filter: blur(4px);
  //background-position: top;
  //position:relative;
  //width: 100vw;
  //height:70vh;
  */
}
</style>
