var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.state.darkMode }
    },
    [
      _c("TheSidebar", { attrs: { role: _vm.role, items: _vm.items } }),
      _c("TheAside", { staticClass: "sidebar" }),
      _c(
        "CWrapper",
        [
          _c("TheHeader"),
          _c("div", { staticClass: "c-body" }, [
            _c(
              "main",
              { staticClass: "c-main main-container" },
              [
                _c(
                  "CContainer",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [
                        _c(
                          "keep-alive",
                          { attrs: { exclude: _vm.keepAliveExcludeList } },
                          [_c("router-view", { key: _vm.$route.fullPath })],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("TheFooter")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }