var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CFooter", { attrs: { fixed: false } }, [
    _c("div", [
      _vm.version
        ? _c("span", { staticClass: "ml-1" }, [
            _vm._v(" Version " + _vm._s(_vm.version))
          ])
        : _vm._e(),
      _c("span", { staticClass: "ml-1" }, [
        _vm._v(" Copyright © " + _vm._s(_vm.copyright))
      ])
    ]),
    _c("div", { staticClass: "mfs-auto" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }