var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-item d-md-down-none mx-2",
      attrs: {
        caret: false,
        placement: "bottom-end",
        "in-nav": "",
        "add-menu-classes": "pt-0"
      },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c(
                "CHeaderNavLink",
                [
                  _c("CIcon", { attrs: { name: "cil-list-rich" } }),
                  _c("CBadge", { attrs: { shape: "pill", color: "warning" } }, [
                    _vm._v(_vm._s(_vm.itemsCount))
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center bg-light", attrs: { tag: "div" } },
        [
          _c("strong", [
            _vm._v("You have " + _vm._s(_vm.itemsCount) + " pending tasks")
          ])
        ]
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "small mb-1" }, [
            _vm._v(" Upgrade NPM & Bower "),
            _c("span", { staticClass: "float-right" }, [
              _c("strong", [_vm._v("0%")])
            ])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "info" }
          })
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "small mb-1" }, [
            _vm._v(" ReactJS Version "),
            _c("span", { staticClass: "float-right" }, [
              _c("strong", [_vm._v("25%")])
            ])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "danger", value: 25 }
          })
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "small mb-1" }, [
            _vm._v(" VueJS Version "),
            _c("span", { staticClass: "float-right" }, [
              _c("strong", [_vm._v("50%")])
            ])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "warning", value: 50 }
          })
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "small mb-1" }, [
            _vm._v(" Add new layouts "),
            _c("span", { staticClass: "float-right" }, [
              _c("strong", [_vm._v("75%")])
            ])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "info", value: 75 }
          })
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "small mb-1" }, [
            _vm._v(" Angular 2 Cli Version "),
            _c("span", { staticClass: "float-right" }, [
              _c("strong", [_vm._v("100%")])
            ])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "success", value: 100 }
          })
        ],
        1
      ),
      _c("CDropdownItem", { staticClass: "text-center border-top" }, [
        _c("strong", [_vm._v("View all tasks")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }